<template>
  <base-layout>
    <v-row no-gutters>
      <v-col class="topMenu menu--container container">
        <v-slide-group
          show-arrows
          center-active
        >
          <v-slide-group-item
            v-for="(item, index) in menu"
            :key="index"
            v-slot="{ isSelected }"
          >
            <v-btn
              v-if="item?.children?.length"
              class="menuItem pa-0"
              :color="isSelected ? 'primary' : undefined"
              size="small"
              :to="(item.to as string)"
            >
              {{ item.title }}
              <v-menu activator="parent" open-on-focus open-on-hover>
                <v-list>
                  <v-list-item
                    v-for="(subItem, subIndex) in item.children"
                    :key="subIndex"
                    :to="(subItem.to as string)"
                  >
                    <v-list-item-title>{{ subItem.title }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-btn>
            <v-btn
              v-else
              class="menuItem pa-0"
              :color="isSelected ? 'primary' : undefined"
              :to="(item.to as string)"
              size="small"
            >
              <span v-html="item.title" />
            </v-btn>
          </v-slide-group-item>
        </v-slide-group>
      </v-col>
    </v-row>
    <v-row no-gutters class="container--content">
      <v-col cols="12">
        <slot />
      </v-col>
    </v-row>
  </base-layout>
</template>

<script setup lang="ts">
const route = useRoute();
const { fullMenu, insertMessagesCount } = useMenu();

async function updateDispatcherMessageCount () {
  const { data, error } = await useApi().message.getDispatcherUnreadMessagesCount();
  if (!error.value && data.value) {
    return data.value;
  }
}
async function updateTechnicianMessageCount () {
  const { data, error } = await useApi().message.getTechnicianUnreadMessagesCount();
  if (!error.value && data.value) {
    return data.value;
  }
}

const menu = computedAsync<MenuItem[]>(async () => {
  const meniItem = fullMenu.find(item => item.to === route.matched[0]?.path);
  let updatePromise;
  switch (meniItem?.title) {
  case "Dispečink":
    updatePromise = updateDispatcherMessageCount();
    break;
  case "Servis":
    updatePromise = updateTechnicianMessageCount();
    break;
  default:
    updatePromise = Promise.resolve();
  }
  if (meniItem){
    insertMessagesCount(meniItem, await updatePromise || 0);}

  return meniItem?.children || [];
}, []);

</script>
